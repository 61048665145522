import "./FindPage.scss";

const FindPage = () => {
    return (
        <div className="FindPage">
        </div>
    );
}

export default FindPage;
